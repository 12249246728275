<template>
    <section class="formular">
        <v-container>
            <v-row no-gutters class="pt-1">
                <v-col class="pa-0 pb-1 grey lighten-4 rounded"
                       :class="{'darken-4' : $vuetify.theme.dark}">
                    <div v-for="(seller,i) in sellers" :key="i"
                            class="px-4 ml-1 mt-1" small :class="{'user-inactive' : !seller.item.active}"
                            color="gold" dark
                            label
                    >
                        <v-icon left small>mdi-account-tie</v-icon>
                        <span>{{ seller.item.fullname }}</span>
                        <span><strong>{{ '&nbsp;- ' + seller.item.amount_to_pay + ' ' + currency}}</strong></span>
                        <span>
                        <v-text-field outlined dense class="pt-2"
                                      color="gold" :prefix="currency"
                                      :label="$t('salary_reports.reduction')"
                                      v-model="seller.item.reduction"
                                      @keyup="updateAmountToPay(seller.item)"
                                      v-validate="'amount_limit|double'"
                                      :data-vv-name="'reduction' + i"
                                      :error-messages="errors.collect('reduction' + i)"
                        />
                            </span>
                    </div>
                </v-col>
            </v-row>

            <v-row v-if="sellers.length > 0" :class="{ 'rgg-grey' : $vuetify.theme.dark }"
                   class="grey lighten-4 rounded pt-1">
                <v-col align="center">
                    <v-btn dense depressed color="gold" dark @click="generate">
                        <v-icon left>mdi-cog-play-outline</v-icon>
                        <span>{{ $t('salary_reports.action_buttons.generate_partner_salaries') }}</span>
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<script>
import {generatePartnerSalaries, getPartnerSalaries} from "@/api/salaries";
import {Utils} from "@/services/utils";

export default {
    name: "PartnersPaymentDialog",

    data: () => ({
        sellers: [],
        currency: "€",
    }),

    methods: {
        updateAmountToPay(item) {
            let value = Utils.parseFloatFromValue(item.amount) - Utils.parseFloatFromValue(item.reduction)
            if (!isNaN(value)) {
                if (value < 0) {
                    value = 0
                }
                item.amount_to_pay = value.toFixed(2).replace('.', ',')
            } else {
                item.amount_to_pay = item.amount
            }
        },

        generate() {
            this.$validator.validateAll().then(() => {
                this.$root.$emit('overlay', true);
                if (!this.$validator.errors.any()) {
                    this.$root.$emit('overlay', true);

                    let data = []
                    for (let i = 0; i < this.sellers.length; i++) {
                        data.push({
                            sellerId: this.sellers[i].item.seller_id,
                            reductionAmount: this.sellers[i].item.reduction.replace(',', '.')
                        })
                    }

                    generatePartnerSalaries(data)
                        .then((response) => {
                            this.$root.$emit('overlay', false);
                            if (response) {
                                this.$parent.$emit('call', {func: 'close', data: null});
                                this.$root.$emit('notification', {
                                    type: 'success',
                                    icon: 'update',
                                    msg: 'notification.generate_partner_salaries'
                                });
                            } else {
                                this.$root.$emit('notification', {
                                    type: 'error',
                                    icon: 'server_error',
                                    msg: 'notification.generate_partner_salaries_error'
                                });
                            }

                        }).catch(() => {
                        this.$root.$emit('overlay', false);
                        this.$root.$emit('notification', {
                            type: 'error',
                            icon: 'info',
                            msg: 'notification.server_error'
                        });
                    })
                } else {
                    this.$root.$emit('overlay', false);
                    this.$root.$emit('notification', {
                        type: 'error',
                        icon: 'info',
                        msg: 'notification.form_error_correct'
                    });
                }
            })
        }
    },

    created() {
        this.$validator.extend('amount_limit', {
            validate: (value) => {
                let remainder = Utils.parseFloatFromValue(this.sellers[0].item.amount) - Utils.parseFloatFromValue(value)
                if (!isNaN(remainder)) {
                    return remainder >= 0
                } else {
                    return false
                }
            }
        });

        this.$validator.extend('double', {
            validate: (value) => {
                return (/^[0-9]*[.,]?[0-9]{0,2}$/g.test(value));
            }
        });
    },

    mounted() {
        this.$root.$emit('overlay', true);
        getPartnerSalaries().then((response) => {
            this.$root.$emit('overlay', false);
            for (let i = 0; i < response.data.length; i++) {
                let tag = {};
                tag.seller_id = response.data[i].sellerId
                tag.fullname = response.data[i].fullName
                tag.active = true
                tag.amount = Utils.parseFloatFromValue(response.data[i].amount.toString()).replace('.', ',')
                tag.reduction = '0,00'
                tag.amount_to_pay = tag.amount
                this.sellers.push({id: i, item: tag});
            }
        }).catch(() => {
            this.$root.$emit('overlay', false);
            this.$root.$emit('notification', {
                type: 'error',
                icon: 'info',
                msg: 'notification.server_error'
            });
        })
    }
}
</script>